<template>
  <div
    class="cash-desk-content"
    :class="stateClass"
  >
    <div
      v-if="order.state === states.IN_PROGRESS"
      class="top"
    >
      <img src="@/static/img/plate.svg">
      <p class="title">
        {{ $t('global.occupied') }}
      </p>
      <p class="subtitle">
        {{ $t('global.order_in_progress') }}
      </p>
    </div>

    <div
      v-if="order.state === states.LOCKED"
      class="top"
    >
      <img src="@/static/img/lock-grey.svg">
      <p class="title">
        {{ $t('global.order.locked') }}
      </p>
      <p class="subtitle">
        {{ $t('global.order_in_progress') }}
      </p>
    </div>

    <div
      v-if="order.state === states.VERIFY_AGE"
      class="top top-verify-age"
    >
      <img src="@/static/img/18plus.svg">
      <p class="title">
        {{ $t('cashier.grid.cashDeskCard.occupied.titleVerifyAge') }}
      </p>
      <p class="subtitle">
        {{ $t('cashier.grid.cashDeskCard.occupied.in_progress') }}
      </p>
    </div>

    <div
      v-if="order.state === states.ASSISTANCE || order.state === states.CUSTOMER_ASSISTANCE"
      class="top top-verify-age"
    >
      <img src="@/static/img/assist.svg">
      <p class="title">
        {{ $t('cashier.cashDeskCard.Occupied.assist') }}
      </p>
      <p class="subtitle">
        {{ $t('cashier.cashDeskCard.Occupied.in_progress') }}
      </p>
    </div>
    <div
      v-if="order.state === states.VOIDED"
      class="top top-verify-age"
    >
      <img src="@/static/img/check_green.svg">
      <p class="title">
        {{ $t('cashier.cashDeskCard.Occupied.voided') }}
      </p>
      <p class="subtitle">
        {{ $t('cashier.cashDeskCard.Occupied.void_finished') }}
      </p>
    </div>
    <div
      v-if="order.state === states.PAID"
      class="top top-verify-age"
    >
      <img src="@/static/img/check_green.svg">
      <p class="title">
        {{ $t('cashier.cashDeskCard.Occupied.paid') }}
      </p>
      <p class="subtitle">
        {{ $t('cashier.cashDeskCard.Occupied.paid_finished') }}
      </p>
    </div>

    <h4 :class="['cash-desk-name', { light: lightText }]">
      {{ cashDeskNumber }}
    </h4>

    <hr>

    <div class="trays row mx-2">
      <div
        v-for="tray in traysToDisplay"
        :key="tray.tray_id"
        :class="['tray', 'col-4', { done: tray.done }]"
      >
        <template v-if="tray.done">
          <img
            v-if="lightText"
            src="@/static/img/check.svg"
          >
          <img
            v-else
            src="@/static/img/check_green.svg"
          >
          <p class="tray-title">
            {{ tray.title }}
          </p>
          <p>{{ $t('cashier.grid.cashDeskCard.occupied.done') }}</p>
        </template>
        <template v-else>
          <img
            v-if="lightText"
            src="@/static/img/processing_white.svg"
          >
          <img
            v-else
            src="@/static/img/processing.svg"
          >
          <p class="tray-title">
            {{ tray.title }}
          </p>
          <p>{{ $t('cashier.grid.cashDeskCard.occupied.processing') }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { CASH_DESK_STATES } from '@/constants';

export default {
  name: 'Occupied',

  props: {
    raspberryId: {
      type: Number,
      required: true,
    },
    cashDeskNumber: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      raspberry: null,
      states: null,
    };
  },

  computed: {
    ...mapState('cashierOrders', {
      ordersByRaspberry: 'byRaspberry',
    }),
    ...mapState('cashierTrays', {
      traysByOrder: 'byOrder',
    }),

    currentTrays() {
      return this.traysByOrder[this.order.id] || [];
    },

    traysToDisplay() {
      const extraLength = Math.max(this.currentTrays.length - 3, 0);
      const lastThreeTrays = this.currentTrays.slice(extraLength);
      const traysToDisplay = lastThreeTrays.map(({ status }, index) => {
        return {
          title: this.trayTitle(index + 1 + extraLength),
          done: this.isTrayDone(status),
        };
      });
      if (this.currentTrays.length > 3) {
        traysToDisplay[0] = {
          title: this.trayGroupTitle(this.currentTrays.length - 2),
          done: true,
        };
      }
      return traysToDisplay;
    },
    ...mapGetters('cashierRaspberries', {
      'raspberryById': 'byId',
    }),
    order() {
      return this.ordersByRaspberry[this.raspberryId];
    },
    stateClass() {
      return 'order-' + this.order.state;
    },
    lightText() {
      return [this.states.ASSISTANCE, this.states.VERIFY_AGE].includes(this.order.state);
    },
  },

  created() {
    this.raspberry = this.raspberryById(this.raspberryId);
    this.states = CASH_DESK_STATES;
  },

  methods: {
    isTrayDone(status) {
      if (!status || status !== 'done') {
        return false;
      } else if (status && status === 'done') {
        return true;
      }
    },
    trayTitle(index) {
      return `${this.$t('global.tray')} ${index}`;
    },
    trayGroupTitle(length) {
      return `+${length} ${this.$t('global.trays')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.fill {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cash-desk-content {
  border-radius: 10px;

  &.order-VERIFY_AGE {
    background: #2F5466;
  }

  &.order-ASSISTANCE, &.order-CUSTOMER_ASSISTANCE {
    background: #B91F31;
  }

  &.order-VERIFY_AGE, &.order-ASSISTANCE, &.order-CUSTOMER_ASSISTANCE {
    p, p.subtitle {
      color: #fff;
    }

    h4 {
      color: #FFFFFF;
      opacity: 0.7;
    }
  }

  &.order-LOCKED {
    background: #DEDEDE;
  }
}

.top {
  padding: 15px 0 0 15px;

  img {
    position: absolute;
    padding-top: 3px;
  }

  p {
    margin-left: 30px;

    &.title {
      font-size: 20px;
      font-weight: 800;
      text-transform: uppercase;
    }

    &.subtitle {
      font-size: 18px;
      color: #777777;
    }
  }
}

.trays {
  display: flex;
}

.tray {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.done {
    color: #759F01;
  }

  img {
    width: 20px;
  }

  .tray-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
  }
}
</style>
