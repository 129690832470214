import '@/config';

import './bootstrap';

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import { i18n, loadLang } from '@/_translate';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import Datepicker from 'vuejs-datepicker';
import { id } from 'vuejs-datepicker/dist/locale';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Loading from './components/Loading';
import Select2 from './components/Select';
import Multiselect from 'vue-multiselect';
import App from './coreui/App';
import router from './router';
import store from './store';
import VueMoment from 'vue-moment';
import * as moment from 'moment';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import { VuexTypes } from '@/store/types';
import DatePicker from 'vue2-datepicker';
import VueSlider from 'vue-slider-component';
import { getHumanProduct } from '@/helpers/tray';
import Api from '@/services/api';
import LidofoodVue from 'lidofood-vue';

import svgJs from './plugins/svg';
Vue.use(svgJs);

loadLang(i18n.locale, true);
/* Load fallback lang for missing translations */
if (i18n.locale !== i18n.fallbackLocale) {
  loadLang(i18n.fallbackLocale, false);
}

const token = localStorage.getItem('user-token');
if (token) {
  Api().defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

Vue.use(LidofoodVue);
Vue.use(vueNumeralFilterInstaller);
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueMoment, { moment });

Vue.filter('state', (value, dirtyOnly = true) => {
  if (dirtyOnly) {
    if (!value.$dirty) { return null; }
  }

  return !value.$invalid ? 'valid' : 'invalid';
});
Vue.filter('dateUtcToLocal', (date) => {
  return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss');
});

Vue.filter('toPrice', function (value) {
  if (typeof value === 'number') {
    return (value / 100).toFixed(2) + '\u20AC';
  }
  if (value?.cash_desk_price) {
    return (value.cash_desk_price / 100).toFixed(2) + '\u20AC';
  }
  if (typeof value === 'string') {
    return (Number.parseInt(value) / 100).toFixed(2) + '\u20AC';
  }
  return '0.00' + '\u20AC';
});

Vue.component('BVueSlider', VueSlider);
Vue.component('BLoading', Loading);
Vue.component('BSelect2', Select2);// Deprecated, use multiselect
Vue.component('Multiselect', Multiselect);
Vue.component('BDatepicker', {
  extends: Datepicker,
  props: {
    bootstrapStyling: {
      type: Boolean,
      default: true,
    },
    language: {
      type: Object,
      default: () => id,
    },
  },
});

store.dispatch(VuexTypes.CHECK_AUTH_INFO);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router: router,
  store,
  render: h => h(App),
}).$mount('#app');
