import Api from '@/services/api';

export default {
  list(filter, signal = null) {
    return Api().get('/trays', {
      params: {
        page: filter.currentPage,
        ids: filter.ids.toString(),
        start_time: filter.fromCreatedAt ? new Date(filter.fromCreatedAt) : null,
        end_time: filter.toCreatedAt ? new Date(filter.toCreatedAt) : null,
        ...filter,
      },
      signal,
    });
  },
  searchOptions(search) {
    return Api().get(`/trays/search-options?search=${search}`);
  },
  cashier(cdid) {
    return Api().get(`/trays/cashier/${cdid}`);
  },
  get(id, workflow) {
    workflow = workflow || '';
    return Api().get(`/trays/${id}?workflow=${workflow}`);
  },
  getNext(id, filter) {
    return Api().get(`/trays/next/${id}?&workflow=${filter.workflow}&annotation_status=${filter.annotation_status}`);
  },
  getImg(id) {
    return Api().get(`/trays/img/${id}`);
  },
  getCashierImg(id) {
    return Api().get(`/trays/cashier_img/${id}`);
  },
  getProducts(id, workflow) {
    workflow = workflow || '';
    return Api().get(`/trays/products/${id}?workflow=${workflow}`);
  },
  store(data) {
    return Api().post('/trays', data);
  },
  update(data) {
    return Api().put(`/trays/${data.id}`, data);
  },
  delete(id) {
    return Api().delete(`/trays/${id}`);
  },
  getResultImage(trayId) {
    return Api().get(`/tray/${trayId}/result-image`, {
      responseType: 'arraybuffer',
    });
  },
  /* CASHIER */
  getForCashier() {
    return Api().get('/cashier/trays');
  },
  cashierDelete(id) {
    return Api().delete(`/cashier/trays/${id}/delete`);
  },
  uploadBase64(trayWithImage) {
    return Api().post('/image/tray', trayWithImage);
  },
  uploadBase64ForTraining(trayWithImage) {
    return Api().post('/trays/storeForTraining', trayWithImage);
  },
  addProduct(trayId, humanProductId) {
    return Api().post(`/cashier/trays/${trayId}/add_tray_product`, { 'HumanProductId': humanProductId });
  },
};
